import React, { useState } from 'react'
import { Tooltip } from 'antd'
import { CopyOutlined, CheckOutlined } from '@ant-design/icons'

const CopyButton = ({ text }) => {
  const [copied, setCopied] = useState(false)

  const getField = async () => {
    await navigator.clipboard.writeText(text)
    setCopied(true)
    setTimeout(() => setCopied(false), 1500)
  }

  return (
    <div onClick={getField}>
      <Tooltip placement="bottom" title={copied ? 'Copied!' : 'Copy subject id'}>
        {copied ? (
          <CheckOutlined style={{ color: 'green', fontSize: '18px' }} />
        ) : (
          <CopyOutlined style={{ fontSize: '18px' }} />
        )}
      </Tooltip>
    </div>
  )
}

export default CopyButton
