import React from 'react'
import { Form, Select } from 'antd'

export const SourceField = ({ index, application, prefillConditionalFields, options }) => {
  return (
    <Form.Item
      label="Source"
      name={[index, 'source']}
      rules={[
        { required: true },
        {
          // check why this message is only in case order
          message: (
            <div>
              FFPE samples cannot be run on
              <br />
              whole genome applications
            </div>
          ),
          validator: (rule, value) => {
            return application?.includes('WG') && value?.includes('FFPE')
              ? Promise.reject()
              : Promise.resolve()
          },
        },
      ]}
      required
    >
      <Select style={{ width: 168 }} onChange={() => prefillConditionalFields()}>
        {options?.sources?.analysis?.map((panel) => (
          <Select.Option key={panel} value={panel}>
            {panel}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
