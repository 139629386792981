import React from 'react'
import { Form, Select } from 'antd'

export const SexField = ({ index, isExisting, buildParentsObject, sampleId, Sex }) => {
  return (
    <Form.Item
      label="Sex"
      name={[index, 'sex']}
      rules={[{ required: !isExisting }]}
      required={!isExisting}
    >
      <Select
        disabled={isExisting}
        style={{ width: 100 }}
        onChange={() => {
          buildParentsObject(sampleId)
        }}
      >
        {Object.keys(Sex).map((sex) => (
          <Select.Option key={sex} value={sex}>
            {sex}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
