import React from 'react'
import { Form, Select } from 'antd'

export const BaitSetField = ({ index, isExisting, validateBaitSet, options }) => {
  return (
    <Form.Item
      label="Bait set"
      name={[index, 'capture_kit']}
      rules={[
        {
          required: !isExisting,
          validator: (rule, value) => {
            return validateBaitSet(value, !isExisting, options)
          },
        },
      ]}
      required={!isExisting}
    >
      <Select style={{ width: 250 }} disabled={isExisting}>
        {options?.beds?.map((bed) => (
          <Select.Option key={bed} value={bed}>
            {bed}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
