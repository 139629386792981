import React from 'react'
import { Form, Input } from 'antd'

export const ContainerNameField = ({
  index,
  containerNameRequirement,
  setContainerName,
  isRML,
}) => {
  return (
    <Form.Item
      label="Container name"
      name={[index, isRML ? 'rml_plate_name' : 'container_name']}
      rules={[{ required: !isRML && containerNameRequirement }]}
      required={!isRML && containerNameRequirement}
    >
      <Input style={{ width: 207 }} onChange={({ target }) => setContainerName(target.value)} />
    </Form.Item>
  )
}
