import React from 'react'
import { Card, Table, Typography } from 'antd'
import { formatDateTime } from 'services/helpers/helpers'

const { Title } = Typography

export const CompletedAnalysesTable = ({ analyses }) => {
  const columns = [
    {
      title: 'Cleaned At',
      dataIndex: 'cleaned_at',
      key: 'cleaned_at',
      render: (date) => formatDateTime(date),
    },
    {
      title: 'Completed At',
      dataIndex: 'completed_at',
      key: 'completed_at',
      render: (date) => formatDateTime(date),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => formatDateTime(date),
    },
    {
      title: 'Delivery Report Created At',
      dataIndex: 'delivery_report_created_at',
      key: 'delivery_report_created_at',
      render: (date) => formatDateTime(date),
    },
    {
      title: 'Case ID',
      dataIndex: 'case_id',
      key: 'case_id',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Is Primary',
      dataIndex: 'is_primary',
      key: 'is_primary',
      render: (sample) => sample.toString(),
    },
    {
      title: 'Pipeline',
      dataIndex: 'pipeline',
      key: 'pipeline',
    },
    {
      title: 'Pipeline Version',
      dataIndex: 'pipeline_version',
      key: 'pipeline_version',
    },
    {
      title: 'Started At',
      dataIndex: 'started_at',
      key: 'started_at',
      render: (date) => formatDateTime(date),
    },
    {
      title: 'Upload Started At',
      dataIndex: 'upload_started_at',
      key: 'upload_started_at',
      render: (date) => formatDateTime(date),
    },
    {
      title: 'Uploaded At',
      dataIndex: 'uploaded_at',
      key: 'uploaded_at',
      render: (date) => formatDateTime(date),
    },
  ]

  return (
    <Card title={<Title level={4}>Completed Analyses</Title>} bordered={false}>
      <Table rowKey="id" pagination={false} columns={columns} dataSource={analyses} />
    </Card>
  )
}
