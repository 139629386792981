import React from 'react'
import { Popconfirm } from 'antd'

export const DeletePopConfirm = ({ itemType, itemName, action, triggerComponent }) => (
  <Popconfirm
    title={`Are you sure you want to delete this ${itemType}`}
    onConfirm={() => action(itemName)}
    okText="Yes"
    cancelText="No"
  >
    {triggerComponent}
  </Popconfirm>
)
