import React from 'react'
import { Form, Select } from 'antd'

export const IndexField = ({ index, prefillConditionalFields, indexSequences }) => {
  return (
    <Form.Item label="Index" name={[index, 'index']} required rules={[{ required: true }]}>
      <Select style={{ width: 340 }} onChange={() => prefillConditionalFields()}>
        {Object.keys(indexSequences).map((index) => (
          <Select.Option key={index}>{index}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
