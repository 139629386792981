import React from 'react'
import { Button, Modal } from 'antd'

export const CookieConsent = ({ visible, accept, reject }) => {
  return (
    <Modal
      open={visible}
      footer={[
        <Button key="reject" onClick={reject}>
          Reject cookies
        </Button>,
        <Button key="accept" type="primary" onClick={accept}>
          Accept cookies
        </Button>,
      ]}
      onCancel={reject}
    >
      <div style={{ margin: '0 10px' }}>
        This website uses{' '}
        <a href={'https://policies.google.com/technologies/cookies?hl=en'}>cookies</a> to deliver
        Google Login services and to keep track of your preferences.
      </div>
    </Modal>
  )
}
