export const getOrderTypeTagColor = (tag: string) => {
  switch (tag.toLowerCase()) {
    case 'cancer':
      return 'gold'
    case 'nipt':
      return 'cyan'
    case 'sequencing only':
      return 'purple'
    case 'rare disease':
      return 'geekblue'
    default:
      return 'gray'
  }
}

export const getStatusTagColor = (tag: string) => {
  switch (tag) {
    case 'running':
      return 'purple'
    case 'hold':
      return 'gold'
    case 'analyze':
      return 'green'
    default:
      return 'geekblue'
  }
}

export const getPipelineTagColor = (tag: string) => {
  switch (tag.toLowerCase()) {
    case 'balsamic':
      return '#16a596'
    case 'mip-dna':
      return '#bc6ff1'
    case 'mip-rna':
      return '#d4380d'
    case 'fluffy':
      return '#e6739f'
    case 'microsalt':
      return '#eb7047'
    case 'sars-cov-2':
      return '#44aeeb'
    case 'demultiplex':
      return '#096194'
    case 'spring':
      return '#5d198a'
    case 'rsync':
      return '#56c46c'
    case 'fastq':
      return '#d48806'
    default:
      return '#737373'
  }
}

export const getDeliveryTagColor = (tag: string) => {
  switch (tag?.toLowerCase()) {
    case 'scout':
      return 'blue'
    case 'fastq':
      return 'magenta'
    case 'fastq_qc':
      return 'green'
    case 'statina':
      return 'cyan'
    case 'analysis':
      return 'purple'
    default:
      return 'cyan'
  }
}

export const getPriorityTagColor = (tag: string) => {
  switch (tag?.toLowerCase()) {
    case 'research':
      return 'orange'
    case 'diagnostic':
      return 'geekblue'
    default:
      return 'cyan'
  }
}

export const sexTags = {
  male: 'purple',
  female: 'magenta',
  unknown: 'cyan',
}

export const getTypeTagColor = (tag: string) => {
  switch (tag) {
    case 'wgs':
      return 'magenta'
    case 'wes':
      return 'purple'
    case 'tgs':
      return 'green'
    case 'cov':
      return 'geekblue'
    case 'rml':
      return 'orange'
    case 'wts':
      return 'blue'
    case 'mic':
      return 'gold'
    default:
      return 'cyan'
  }
}
