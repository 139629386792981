import React from 'react'
import { Form, Input } from 'antd'

export const SourceCommentField = ({ index }) => {
  return (
    <Form.Item
      label={'Which other source?'}
      name={[index, 'source_comment']}
      rules={[{ required: true }]}
      required
    >
      <Input placeholder={'Which other source?'} style={{ width: 245 }} />
    </Form.Item>
  )
}
