import React from 'react'
import { Form, Input } from 'antd'

export const CommentField = ({ index }) => {
  return (
    <Form.Item label="Comment" name={[index, 'comment']}>
      <Input style={{ width: 310 }} />
    </Form.Item>
  )
}
