import React from 'react'
import { DeleteTwoTone } from '@ant-design/icons'
import { Button } from 'antd'
import { DeletePopConfirm } from 'components/DeletePopConfirm'

export const DeleteButton = ({ remove, index, sampleId }) => {
  return (
    <DeletePopConfirm
      itemType={'sample'}
      action={() => {
        remove(index)
      }}
      itemName={sampleId}
      triggerComponent={
        <Button icon={<DeleteTwoTone />} block>
          Sample
        </Button>
      }
    />
  )
}
