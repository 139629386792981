import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getApplications } from 'services/StatusDbApi'
import { ErrorPage } from 'pages/Error/ErrorPage'
import { Button, Popover, Space, Table, Typography } from 'antd'
import { QuestionCircleTwoTone } from '@ant-design/icons'
import Search from 'antd/lib/input/Search'
import { UserContext } from 'services/contexts/userContext'

export function ApplicationsPage() {
  const userContext = useContext(UserContext)
  const [items, setItems] = useState<any[]>([])
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { Title } = Typography

  useEffect(() => {
    getApplications(userContext)
      .then((response) => {
        setFilteredItems(Object.values(response)[0] as any[])
        setItems(Object.values(response)[0] as any[])
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
      })
  }, [userContext])

  const columns = [
    {
      title: 'Analysis code',
      dataIndex: 'tag',
      key: 'tag',
      render: (tag) => (
        <>
          <Link to={`/applications/${tag}`}>{tag}</Link>
        </>
      ),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      width: '80%',
    },
  ]

  const onSearch = (searchInput) => {
    const matches = items.filter((item) => item.tag.toLowerCase().match(searchInput.toLowerCase()))
    setFilteredItems(matches)
  }

  const applicationsInfo = (
    <div style={{ maxWidth: '600px' }}>
      <p>
        For each project, we together identify the methods (laboratory and bioinformatic) that are
        needed and then we set up the analysis. Each analysis is defined using a unique analysis
        code (application tag) that clearly defines how the analysis is to be performed. It is
        important that the correct code is used in the order form.
      </p>
      <p>
        The following analyzes are prepared and can be ordered. The number of reads can be adjusted
        as desired and below are examples of analyses with 100 M read pairs for exome samples and
        60X/30X for WGS samples. Contact us if any other type of analysis is needed.
      </p>
    </div>
  )

  return (
    <>
      {error ? (
        <ErrorPage error={error} />
      ) : (
        <Table
          columns={columns}
          dataSource={filteredItems}
          rowKey="id"
          bordered
          loading={isLoading}
          pagination={false}
          title={() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space>
                  <Title level={4}>Applications</Title>
                  <Popover content={applicationsInfo} title="Analyses" trigger="hover">
                    <Button
                      shape="circle"
                      type="text"
                      icon={<QuestionCircleTwoTone />}
                      style={{ marginBottom: '10px' }}
                    ></Button>
                  </Popover>
                </Space>
              </div>
              <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} allowClear />
            </div>
          )}
        />
      )}
    </>
  )
}
