import React, { useEffect, useContext, useState } from 'react'
import { UserContext } from '../../services/contexts/userContext'
import { Table, Typography } from 'antd'
import { escapeRegExp } from 'services/helpers/helpers'
import Search from 'antd/lib/input/Search'
import { ErrorPage } from '../../pages/Error/ErrorPage'

export const GeneralTable = ({ getItems, columns, title }) => {
  const userContext = useContext(UserContext)
  const [filteredItems, setFilteredItems] = useState<any[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [action, setAction] = useState<string>('')
  const [error, setError] = useState()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const { Title, Text } = Typography

  useEffect(() => {
    getItems(userContext, searchValue)
      .then((response) => {
        setFilteredItems(Object.values(response)[0] as any[])
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
      })
  }, [userContext, getItems, searchValue])

  const onSearch = (searchInput) => {
    const escapeInput = escapeRegExp(searchInput)
    setSearchValue(escapeInput)
    getItems(userContext, escapeInput, action)
      .then((response) => {
        setFilteredItems(Object.values(response)[0] as any[])
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
      })
  }

  const onChange = (pagination, filters) => {
    setIsLoading(true)
    setAction(filters.action)
    getItems(userContext, searchValue, filters.action)
      .then((response) => {
        setFilteredItems(Object.values(response)[0] as any[])
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
      })
  }

  return (
    <>
      {error ? (
        <ErrorPage error={error} />
      ) : (
        <Table
          columns={columns}
          dataSource={filteredItems}
          rowKey="id"
          bordered
          onChange={onChange}
          loading={isLoading}
          pagination={false}
          title={() => (
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <>
                <Title level={4}>{title}</Title>
                <Text type={'secondary'}>only the newest 50 {title.toLowerCase()} are shown</Text>
              </>
              <Search placeholder="Search" onSearch={onSearch} style={{ width: 200 }} allowClear />
            </div>
          )}
        />
      )}
    </>
  )
}
