import React from 'react'
import { Form, Select } from 'antd'

export const ControlField = ({ index, isExisting, controls }) => {
  return (
    <Form.Item label="Control" name={[index, 'control']}>
      <Select style={{ width: 90 }} disabled={isExisting}>
        {controls.map((control) => (
          <Select.Option key={control} value={control}>
            {control}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
