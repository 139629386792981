import React from 'react'
import { Link } from 'react-router-dom'
import { sexTags } from '../../services/helpers/styleHelpers'
import { formatDate } from '../../services/helpers/helpers'
import { Card, Table, Tag } from 'antd'

export const CaseSamplesTable = ({ caseLinks }) => {
  const columns = [
    {
      title: 'Samples',
      dataIndex: 'sample',
      key: 'sample',
      render: (sample) => <Link to={`/samples/${sample?.internal_id}`}>{sample?.name}</Link>,
    },
    {
      title: 'Sex',
      dataIndex: 'sample',
      key: 'sample',
      render: (sample) => sample?.sex && <Tag color={sexTags[sample.sex]}>{sample.sex}</Tag>,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Mother',
      dataIndex: 'mother',
      key: 'mother',
      render: (sample) => <Link to={`/samples/${sample?.internal_id}`}>{sample?.name}</Link>,
    },
    {
      title: 'Father',
      dataIndex: 'father',
      key: 'father',
      render: (sample) => <Link to={`/samples/${sample?.internal_id}`}>{sample?.name}</Link>,
    },
    {
      title: 'Sequenced',
      dataIndex: 'sample',
      key: 'sample',
      render: (sample) => formatDate(sample?.last_sequenced_at),
    },
    {
      title: 'Customer',
      dataIndex: 'sample',
      key: 'sample',
      render: (sample) => (
        <>
          {sample?.customer?.internal_id} - {sample?.customer?.name}
        </>
      ),
    },
    {
      title: 'Application',
      dataIndex: 'sample',
      key: 'sample',
      render: (sample) => <>{sample?.application?.tag}</>,
    },
    {
      title: 'Capture kit',
      dataIndex: 'sample',
      key: 'sample',
      ellipsis: {
        showTitle: false,
      },
      render: (sample) => <>{sample?.capture_kit}</>,
    },
    {
      title: 'Comment',
      dataIndex: 'sample',
      key: 'sample',
      render: (sample) => <>{sample?.comment}</>,
    },
  ]

  return (
    <Card bordered={false}>
      <Table rowKey="id" pagination={false} columns={columns} dataSource={caseLinks} />
    </Card>
  )
}
