import React from 'react'
import { DatePicker, Form } from 'antd'

export const CollectionDateField = ({ index }) => {
  return (
    <Form.Item
      label="Collection date"
      name={[index, 'collection_date']}
      rules={[{ required: true }]}
      required
    >
      <DatePicker />
    </Form.Item>
  )
}
