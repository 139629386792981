import React, { useContext } from 'react'
import { Card, Descriptions, Tag, Typography } from 'antd'
import { UserAvatar } from '../../components/Avatar/Avatar'
import { UserContext } from '../../services/contexts/userContext'

export const AccountPage = () => {
  const userContext = useContext(UserContext)
  const { Title } = Typography
  return (
    <>
      {userContext.user?.name && (
        <Card style={{ marginBottom: 20 }}>
          <Descriptions title={<UserInfo userInfo={userContext.user} />}>
            <Descriptions.Item label="Email">{userContext.user.email}</Descriptions.Item>
          </Descriptions>
          <br />
          {userContext.user.customers.map((customer) => (
            <Descriptions title={<Title level={5}>{customer.name}</Title>} key={customer.id}>
              <Descriptions.Item label="id">{customer.internal_id}</Descriptions.Item>
              <Descriptions.Item label="Invoice reference">
                {customer.invoice_reference}
              </Descriptions.Item>
              <Descriptions.Item label="Organisation number">
                {customer.organisation_number}
              </Descriptions.Item>
              <Descriptions.Item label="Invoice address">
                {customer.invoice_address}
              </Descriptions.Item>
            </Descriptions>
          ))}
        </Card>
      )}
    </>
  )
}

export const UserInfo = ({ userInfo }) => {
  const { Text } = Typography
  return (
    <div style={{ display: 'flex', gap: 20, alignItems: 'center' }}>
      <UserAvatar user={userInfo} />
      <Text strong>{userInfo.name} </Text>
      {userInfo.is_admin && <Tag color={'blue'}>Admin</Tag>}
    </div>
  )
}
