import React from 'react'
import { Form, InputNumber, Tooltip } from 'antd'

export const ConcentrationSampleNMField = ({ index }) => {
  return (
    <Tooltip title={'Sample concentration (nM)'}>
      <Form.Item label="nM" name={[index, 'concentration_sample']}>
        <InputNumber style={{ width: 65 }} />
      </Form.Item>
    </Tooltip>
  )
}
