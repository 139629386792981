import React from 'react'
import { Form, Switch } from 'antd'

export const TumourField = ({ index, setIsTumour, isExisting }) => {
  return (
    <Form.Item label="Is Tumour" name={[index, 'tumour']} valuePropName="checked">
      <Switch onChange={(e) => setIsTumour && setIsTumour(e)} disabled={isExisting} />
    </Form.Item>
  )
}
