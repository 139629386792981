import React from 'react'
import { Form, InputNumber } from 'antd'

export const IndexNumberField = ({ index, prefillConditionalFields, indexSequence }) => {
  return (
    <Form.Item
      label="Index number"
      name={[index, 'index_number']}
      required
      rules={[{ required: true }]}
    >
      <InputNumber
        style={{ width: 410 }}
        onChange={() => prefillConditionalFields()}
        addonAfter={<div style={{ width: 330 }}>{indexSequence}</div>}
      />
    </Form.Item>
  )
}
