import React from 'react'
import { Form, Input } from 'antd'

export const ReferenceGenomeStandaloneField = ({ index }) => {
  return (
    <Form.Item
      label={
        <div>
          <a
            href={'https://www.ncbi.nlm.nih.gov/genome/browse/#!/overview/'}
            target="_blank"
            rel="noreferrer"
          >
            Reference genome
          </a>
        </div>
      }
      name={[index, 'reference_genome']}
      rules={[{ required: true }]}
      required
    >
      <Input style={{ width: 150 }} placeholder={'e.g. NC_001928'} />
    </Form.Item>
  )
}
