import { setupWorker, rest } from 'msw'

const { REACT_APP_CLINICAL_URL } = process.env

const getEndpoints = {
  cases: 'cases?enquiry=',
}

const handleCaseRequest = (req, res, ctx) => {
  const statusCode = 401 as number // Set the desired status code for the response

  let errorMessage
  switch (statusCode) {
    case 401:
      errorMessage = 'Unauthorized: User is not authenticated'
      break
    case 404:
      errorMessage = 'Not found: The requested resource does not exist'
      break
    case 409:
      errorMessage = "Conflict: Sample can't be found in the database"
      break
    default:
      errorMessage = 'Internal server error: Something went wrong on the server'
  }

  return res(ctx.status(statusCode), ctx.json({ message: errorMessage }))
}

const worker = setupWorker(
  rest.get(`${REACT_APP_CLINICAL_URL}/${getEndpoints.cases}`, handleCaseRequest)
)

worker.start()
