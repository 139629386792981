import React from 'react'
import { Form, Select, Tooltip } from 'antd'

export const ElutionBufferField = ({
  index,
  isBufferRequired,
  application,
  skipReceptionControl,
  buffers,
  standalone,
}) => {
  return (
    <Tooltip title={'Elution buffer'}>
      <Form.Item
        label="Buffer"
        name={[index, 'elution_buffer']}
        rules={[
          { required: standalone ? true : isBufferRequired(application) }, // check why there is a need for this only in case form
          {
            validator: (rule, value) => {
              return skipReceptionControl && value?.includes('Other')
                ? Promise.reject()
                : Promise.resolve()
            },
            message: skipReceptionControl
              ? 'Only Tris-HCl and nuclease-free water allowed when skipping reception control.'
              : '',
          },
        ]}
        required={isBufferRequired(application)}
      >
        <Select style={{ width: 230 }}>
          {buffers.map((buffer) => (
            <Select.Option key={buffer}>{buffer}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  )
}
