import React from 'react'
import { Form, Select } from 'antd'

export const PriorityField = ({ index, priorities }) => {
  return (
    <Form.Item label="Priority" rules={[{ required: true }]} required name={[index, 'priority']}>
      <Select style={{ width: 120 }}>
        {priorities.map(({ value, text }) => (
          <Select.Option key={value} value={value}>
            {text}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
