import React from 'react'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { Card, Skeleton } from 'antd'
import styles from './News.module.css'
import { isNil } from 'ramda'

export const News = ({ posts }) => {
  return isNil(posts) ? (
    <div className={styles.newsContainer}>
      <Card className={styles.newsCard} style={{ height: 300 }}>
        <Skeleton active />
      </Card>
      <Card className={styles.newsCard} style={{ height: 300 }}>
        <Skeleton active />
      </Card>
      <Card className={styles.newsCard} style={{ height: 300 }}>
        <Skeleton active />
      </Card>
      <Card className={styles.newsCard} style={{ height: 300 }}>
        <Skeleton active />
      </Card>
    </div>
  ) : posts.length > 0 ? (
    <div className={styles.newsContainer}>
      {posts.map((page, i) => (
        <Card className={styles.newsCard} key={i}>
          {documentToReactComponents(page.fields.content)}
        </Card>
      ))}
    </div>
  ) : (
    <div>
      <Card
        title="Welcome to the Clinical Genomics Stockholm Order Portal"
        className={styles.newsCard}
      >
        <div>
          <h3>
            Sign in to make an order or contact{' '}
            <a href="mailto: support@clinicalgenomics.se">support</a> for inquiries about new
            projects.
          </h3>
        </div>
        The{' '}
        <a href={'https://www.scilifelab.se/units/clinical-genomics-stockholm/'}>
          Clinical Genomics Stockholm unit
        </a>{' '}
        provides a dedicated research infrastructure for projects utilising massively parallel /
        next generation sequencing technologies. <br />
        All projects are carried out in close collaboration with the Swedish healthcare system.{' '}
        <br /> The unit serves as a competence center assisting the translation of genomics-based
        tools to routine clinical care. All work is carried out in close collaboration with medical
        expertise provided by the clinical diagnostic laboratories and patients’ managing
        physicians. <br />
        Also, the unit aims to improve the capacity of the public health microbiology for national
        surveillance of infectious diseases and for epidemic preparedness.
      </Card>
    </div>
  )
}
