import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../services/contexts/userContext'
import { Card, Descriptions, Divider, Skeleton, Tag } from 'antd'
import { useLocation } from 'react-router-dom'
import { getApplication } from 'services/StatusDbApi'
import { formatDate } from 'services/helpers/helpers'
import { getTypeTagColor } from 'services/helpers/styleHelpers'

export const ApplicationPage = () => {
  const userContext = useContext(UserContext)
  const { pathname } = useLocation()
  const applicationId = pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length)
  const [application, setApplication] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    getApplication(userContext, applicationId).then((res) => {
      setApplication(res)
      setTimeout(() => {
        setIsLoading(false)
      }, 500)
    })
  }, [userContext, applicationId])

  return (
    <Card>
      {isLoading ? (
        <Skeleton active paragraph={{ rows: 11 }} />
      ) : (
        <Descriptions title={applicationId} bordered column={3}>
          <Descriptions.Item label="Last updated">
            {application?.updated_at ? formatDate(application?.updated_at) : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Prep category">
            {application?.prep_category ? (
              <Tag color={getTypeTagColor(application?.prep_category)}>
                {application?.prep_category.toUpperCase()}
              </Tag>
            ) : (
              '-'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Turnaround time (Day)">
            {application?.turnaround_time ? application?.turnaround_time : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Sample amount">
            {application?.sample_amount ? application?.sample_amount + ' ng' : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Accredited">
            {application?.is_accredited ? 'ISO/IEC 17025:2017' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Priority processing">
            {application?.priority_processing ? 'Yes' : 'No'}
          </Descriptions.Item>
          <Descriptions.Item label="Description" span={3}>
            {application?.description ? application?.description : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Details" span={3}>
            {application?.details ? application?.details : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Limitations" span={3}>
            {application?.limitations ? application?.limitations : '-'}
          </Descriptions.Item>
          <Descriptions.Item label="Workflow Limitations" span={3}>
            {application?.workflow_limitations.length > 0
              ? application?.workflow_limitations.map(
                  (limitations: any, index: number, array: any[]) => (
                    <>
                      <strong>{limitations?.workflow.toUpperCase()}</strong>:{' '}
                      {limitations.limitations}
                      {index !== array.length - 1 && <Divider />}
                    </>
                  )
                )
              : '-'}
          </Descriptions.Item>
        </Descriptions>
      )}
    </Card>
  )
}
