import React from 'react'
import { Form, Input } from 'antd'

export const PoolField = ({ index }) => {
  return (
    <Form.Item label="Pool" name={[index, 'pool']} required>
      <Input style={{ width: 90 }} />
    </Form.Item>
  )
}
