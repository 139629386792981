import React from 'react'
import { Form, Select } from 'antd'

export const WellPositionField = ({
  index,
  form,
  validateWellIsFree,
  containerName,
  plateLayout,
  standalone,
  isRML,
}) => {
  return (
    <Form.Item
      label="Well"
      name={[index, isRML ? 'well_position_rml' : 'well_position']}
      rules={[
        { required: true },
        {
          message: <div> This well already contains a sample </div>,
          validator: (rule, well) => {
            // will be removed when adding the server validation
            if (standalone) {
              const samples = form.getFieldValue('samples')
              return validateWellIsFree(well, containerName, samples)
            } else {
              const cases = form.getFieldValue('cases')
              const samples = cases.flatMap((localCase) => localCase.samples)
              return validateWellIsFree(well, containerName, samples)
            }
          },
        },
      ]}
      required
    >
      <Select style={{ width: 70 }}>
        {plateLayout().map((well) => (
          <Select.Option key={well} value={well}>
            {well}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
