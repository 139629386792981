const { REACT_APP_CONTENTFUL_URL, REACT_APP_CONTENTFUL_TOKEN } = process.env

const axios = require('axios').default
const contentfulSpaceId = 't09bjek8qqj2'

const axiosGET = (endPoint) => {
  return new Promise((resolve, reject) => {
    axios
      .get(endPoint)
      .then(function (response) {
        resolve(response.data)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const getContent = async (
  contentType: 'page' | 'newsPost' | 'contactUs' = 'page',
  locale: string,
  spaceId = contentfulSpaceId
): Promise<any> => {
  const endPoint = `${REACT_APP_CONTENTFUL_URL}/spaces/${spaceId}/entries?select=fields&content_type=${contentType}&locale=${locale}&access_token=${REACT_APP_CONTENTFUL_TOKEN}`
  return axiosGET(endPoint)
}
