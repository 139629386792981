import React from 'react'
import { Card, Col, Row } from 'antd'

export const ContactUs = ({ contacts }) => {
  return (
    <Card>
      <Card title="Contact for general questions:" style={{ marginBottom: 16 }}>
        <p>+46 85 248 15 00</p>
        <p>support@clinicalgenomics.se</p>
      </Card>
      <Card title="Contact regarding:">
        <Row gutter={[16, 16]}>
          {contacts?.map((post, i) => (
            <Col key={i} span={8}>
              <Card>
                <p style={{ fontWeight: 'bold' }}>{post.fields.info}</p>
                <p>{post.fields.name}</p>
                <p>{post.fields.phoneNumber}</p>
                <p>{post.fields.email}</p>
                <p>{post.fields.title}</p>
              </Card>
            </Col>
          ))}
        </Row>
      </Card>
    </Card>
  )
}
