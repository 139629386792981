import React from 'react'
import { Form, Select } from 'antd'

export const RegionField = ({ index, regions }) => {
  return (
    <Form.Item label="Region" name={[index, 'region']} rules={[{ required: true }]} required>
      <Select style={{ width: 140 }}>
        {regions.map((region) => (
          <Select.Option key={region}>{region}</Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
