import React from 'react'
import { Form, InputNumber, Tooltip } from 'antd'

export const ConcentrationField = ({
  index,
  skipReceptionControl,
  applicationObj,
  getConcentrationRules,
  source,
}) => {
  return (
    <Tooltip title={'Concentration (ng/μL)'}>
      <Form.Item
        label="Concentration (ng/μL)"
        name={[index, 'concentration_ng_ul']}
        required={skipReceptionControl}
        rules={
          skipReceptionControl && applicationObj
            ? getConcentrationRules(skipReceptionControl, applicationObj, source)
            : []
        }
      >
        <InputNumber style={{ width: 65 }} />
      </Form.Item>
    </Tooltip>
  )
}
