import React, { useContext, useEffect, useState } from 'react'
import {
  Button,
  Card,
  Checkbox,
  Form,
  Input,
  Modal,
  Popconfirm,
  Row,
  Select,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from 'antd'
import { getApplicationTags, getOptions, REACT_APP_CLINICAL_URL } from '../../services/StatusDbApi'
import { UserContext } from '../../services/contexts/userContext'
import {
  DeleteTwoTone,
  FileAddTwoTone,
  InfoCircleTwoTone,
  DownCircleOutlined,
} from '@ant-design/icons'
import { isNil } from 'ramda'
import { ErrorNotification } from '../../services/helpers/helpers'
import {
  allowedDeliveryOptions,
  carrierStatus,
  isBalsamic,
  isMIPDNA,
  isStandaloneSampleForm,
  orderTypes,
  priorities,
  supportSystemURL,
} from '../../services/helpers/constants'
import { ErrorPage } from '../Error/ErrorPage'
import {
  buildCaseFormFromFile,
  submitOrder,
  validateCaseOrder,
  buildStandaloneSampleFormFromFile,
  validateStandaloneOrder,
  findUniqueItems,
  removeFileExtension,
  isSampleOld,
  initIsTrustedCustomer,
} from './helpers'
import styles from './OrderForm.module.css'
import { StandaloneSampleFormList } from '../../components/Forms/StandaloneSampleFormList'
import { CaseFormList } from '../../components/Forms/CaseFormList'

export const OrderFormPage = () => {
  const [options, setOptions] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmittingOrder, setIsSubmittingOrder] = useState(false)
  const [error, setError] = useState()
  const [validationError, setValidationError] = useState<any>({ hasError: false, messages: [] })
  const [selectedAnalysisType, setSelectedAnalysisType] = useState<string | null>(null)
  const [dataDelivery, setDataDelivery] = useState<string | null>(null)
  const [customer, setCustomer] = useState<any>()
  const [isTrusted, setIsTrusted] = useState<boolean>(false)
  const [isExistingTicket, setIsExistingTicket] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [isSkipReceptionControl, setIsSkipReceptionControl] = useState(false)
  const [orderData, setOrderData] = useState<any>()
  const [applicationTags, setApplicationTags] = useState([])
  const userContext = useContext(UserContext)
  const { Dragger } = Upload
  const [form] = Form.useForm()
  const { Text } = Typography

  useEffect(() => {
    if (selectedAnalysisType) {
      getApplicationTags(userContext, selectedAnalysisType).then(({ applications }) =>
        setApplicationTags(applications)
      )
    }
  }, [selectedAnalysisType, userContext])

  useEffect(() => {
    getOptions(userContext)
      .then((response) => {
        setOptions(response)
        setIsLoading(false)
      })
      .catch((error) => {
        setError(error)
        setIsLoading(false)
      })
  }, [userContext])

  const uploadProps = {
    action: `${REACT_APP_CLINICAL_URL}/orderform`,
    accept: '.xlsx,.json',
    headers: { Authorization: 'Bearer ' + userContext?.token },
    maxCount: 1,
    showUploadList: { showRemoveIcon: false },
    onChange: ({ file }) => {
      if (file.status === 'error') {
        ErrorNotification('Could not upload file', file?.response?.message)
      } else if (!isNil(file.response)) {
        form.resetFields()
        const projectType = file.response.project_type
        setCustomer(file.response.customer)
        setDataDelivery(file.response.delivery_type)
        setIsTrusted(initIsTrustedCustomer(file.response.customer, options))
        setSelectedAnalysisType(projectType)
        isStandaloneSampleForm(projectType)
          ? buildStandaloneSampleFormFromFile(file.response, removeFileExtension(file.name), form)
          : buildCaseFormFromFile(file.response, removeFileExtension(file.name), form)
      }
    },
  }
  return (
    <div>
      {isLoading ? (
        <Skeleton paragraph={{ rows: 5 }} />
      ) : error ? (
        <ErrorPage error={error} />
      ) : (
        <>
          <Card>
            <Spin tip={'Submitting order...'} spinning={isSubmittingOrder}>
              <div style={{ paddingBottom: 10 }}>
                <Dragger {...uploadProps} data-testid={'uploader'}>
                  <FileAddTwoTone style={{ fontSize: 16 }} /> Click or drag file to this area to
                  upload
                </Dragger>
                <Text type="secondary" style={{ fontSize: 10 }}>
                  Accepts{' '}
                  <a href={`${supportSystemURL}kb/index.php`} target="_blank" rel="noreferrer">
                    orderform
                  </a>{' '}
                  Excel (.xlsx) and JSON (.json) files for Balsamic, MIP and RML
                </Text>
              </div>
              <Modal
                title={
                  <div
                    style={{ display: 'flex', justifyContent: 'space-between', marginRight: 30 }}
                  >
                    {orderData?.name} <Tag>{orderData?.customer}</Tag>
                  </div>
                }
                open={isConfirmationModalOpen && !isSubmittingOrder}
                okButtonProps={{ form: 'confirm', htmlType: 'submit' }}
                onCancel={() => setIsConfirmationModalOpen(false)}
                okText={'Submit order'}
                width={800}
              >
                <p>{orderData?.samples.length} samples</p>
                {orderData?.samples.map((sample) => (
                  <div key={sample.name} style={{ marginBottom: '8px', display: 'flex' }}>
                    <div style={{ flex: '0 0 40%', display: 'flex' }}>
                      <Text strong style={{ width: '150px' }}>
                        {sample.name}
                      </Text>
                      <Tag style={{ height: 'fit-content', marginLeft: '10px' }}>
                        {sample.application}
                      </Tag>
                    </div>
                    {sample?.priority && (
                      <div style={{ flex: '0 0 15%' }}>
                        <Tag
                          color={
                            priorities.find((priority) => priority.value === sample?.priority)
                              ?.tagColor
                          }
                        >
                          {priorities.find((priority) => priority.value === sample?.priority)
                            ?.text || sample?.priority}
                        </Tag>
                      </div>
                    )}
                    {selectedAnalysisType && isMIPDNA(selectedAnalysisType) && (
                      <div style={{ flex: '0 0 15%' }}>
                        <Tag
                          color={
                            carrierStatus.find((carrier) => carrier.status === sample.status)
                              ?.badgeColor || 'default'
                          }
                        >
                          {sample.status}
                        </Tag>
                      </div>
                    )}
                    {selectedAnalysisType && isBalsamic(selectedAnalysisType) && sample.tumour && (
                      <div style={{ flex: '0 0 15%' }}>
                        <Tag color={'geekblue'}>TUMOUR</Tag>
                      </div>
                    )}

                    {sample?.received_at && isSampleOld(sample.received_at) && (
                      <div style={{ flex: '0 0 15%' }}>
                        <Tooltip
                          title={
                            'You have placed an order of old data. Due to the legacy structure of this old data we will have to manually process it. We cannot guarantee fast delivery of such data - If time is of concern please consider asking us about possible re-sequencing of existing material or sending in a new aliquot.'
                          }
                        >
                          <Tag color={'red'}>
                            OLD DATA <InfoCircleTwoTone twoToneColor={'red'} />
                          </Tag>
                        </Tooltip>
                      </div>
                    )}
                    {sample.control && (
                      <div>
                        <Tag style={{ height: 'fit-content' }}>{sample.control}</Tag>
                      </div>
                    )}
                  </div>
                ))}

                <div>
                  <Form
                    name="confirm"
                    scrollToFirstError={true}
                    onFinish={() =>
                      submitOrder(
                        userContext,
                        selectedAnalysisType,
                        orderData,
                        form,
                        setIsConfirmationModalOpen,
                        setIsSubmittingOrder
                      )
                    }
                  >
                    <Form.Item
                      valuePropName={'checked'}
                      name="requirements"
                      required
                      rules={[
                        {
                          required: true,
                          transform: (value) => value || undefined,
                          type: 'boolean',
                          message: 'Please comply with the requirements',
                        },
                      ]}
                    >
                      <Checkbox style={{ marginTop: '25px' }}>
                        I have read and I comply with the{' '}
                        <a target={' _blank'} href={'/sample-requirements'}>
                          Clinical Genomics Sample requirements
                        </a>
                      </Checkbox>
                    </Form.Item>
                  </Form>
                </div>
              </Modal>
              <Form
                title={'New order'}
                validateMessages={{ required: 'required' }}
                colon={false}
                onFinish={(formValue) => {
                  selectedAnalysisType && isStandaloneSampleForm(selectedAnalysisType)
                    ? validateStandaloneOrder(
                        formValue,
                        userContext,
                        setValidationError,
                        setIsConfirmationModalOpen,
                        setOrderData
                      )
                    : validateCaseOrder(
                        formValue,
                        userContext,
                        setValidationError,
                        setIsConfirmationModalOpen,
                        setOrderData
                      )
                }}
                onFinishFailed={({ errorFields }) => {
                  ErrorNotification(
                    'Validation errors',
                    <>
                      {findUniqueItems(errorFields).map((error: any) =>
                        selectedAnalysisType && isStandaloneSampleForm(selectedAnalysisType) ? (
                          <a href={`#sample ${error?.name[1]}`} key={`#sample ${error?.name[1]}`}>
                            <Text type="danger">
                              <DownCircleOutlined color={'red'} />{' '}
                              {form.getFieldValue(['samples', error?.name[1], 'name'])}
                            </Text>
                            <br />
                          </a>
                        ) : (
                          <a href={`#case ${error?.name[1]}`} key={`#case ${error?.name[1]}`}>
                            <Text type="danger">
                              <DownCircleOutlined color={'red'} />{' '}
                              {form.getFieldValue(['cases', error?.name[1], 'name'])}
                            </Text>
                            <br />
                          </a>
                        )
                      )}
                    </>
                  )
                }}
                size={'small'}
                form={form}
              >
                <Row className={styles.formRow}>
                  <Form.Item name={'customer'}>
                    <Select
                      onSelect={(selection, option) => {
                        setCustomer(selection)
                        setIsTrusted(option.isTrusted)
                      }}
                      style={{ width: 300 }}
                      showSearch
                      placeholder="Select a customer"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      options={options?.customers?.map(({ value, text, isTrusted }) => {
                        return {
                          value: value,
                          label: text,
                          isTrusted: isTrusted,
                        }
                      })}
                    />
                  </Form.Item>
                  <Form.Item name="project_type" required label="Type">
                    <Select
                      onChange={(e) => {
                        setSelectedAnalysisType(e)
                        setDataDelivery(null)
                        form.setFieldValue('data_delivery', null)
                      }}
                      style={{ width: 300 }}
                      showSearch
                      placeholder="Select an analysis type"
                      optionFilterProp="children"
                      filterOption={(input, option: any) =>
                        option?.label?.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      options={orderTypes.map(({ text, orderType }) => ({
                        value: orderType,
                        label: text,
                      }))}
                    ></Select>
                  </Form.Item>
                  {selectedAnalysisType ? (
                    <Form.Item label="Delivery" name={'data_delivery'} required>
                      <Select style={{ width: 215 }} onChange={(e) => setDataDelivery(e)}>
                        {Array.isArray(allowedDeliveryOptions[selectedAnalysisType])
                          ? allowedDeliveryOptions[selectedAnalysisType].map(({ value, text }) => (
                              <Select.Option key={value} value={value}>
                                {text}
                              </Select.Option>
                            ))
                          : null}
                      </Select>
                    </Form.Item>
                  ) : null}

                  <Form.Item label="Connect to ticket">
                    <Checkbox onChange={({ target }) => setIsExistingTicket(target.checked)} />
                  </Form.Item>
                  {isTrusted && (
                    <Form.Item
                      label="Skip reception control"
                      valuePropName={'checked'}
                      name="skip_reception_control"
                    >
                      <Checkbox
                        onChange={({ target }) => {
                          setIsSkipReceptionControl(target.checked)
                        }}
                      />
                    </Form.Item>
                  )}
                </Row>
                <Row className={styles.formRow}>
                  {isExistingTicket ? (
                    <Form.Item
                      label="Ticket number"
                      required
                      name="name"
                      rules={[{ required: true }, { min: 4 }]}
                    >
                      <Input />
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                        label="Order name"
                        name="name"
                        rules={[{ required: true }]}
                        required
                        style={{ width: 300 }}
                      >
                        <Input />
                      </Form.Item>
                      <Form.Item label="Comment" name="comment" style={{ width: 794 }}>
                        <Input />
                      </Form.Item>
                    </>
                  )}
                </Row>
                <Row className={styles.formRow}>
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Submit order
                    </Button>
                  </Form.Item>
                  <Popconfirm
                    title={`Are you sure you want to reset the order`}
                    onConfirm={() => form.resetFields()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button icon={<DeleteTwoTone />}>Reset order</Button>
                  </Popconfirm>
                </Row>
                {customer && selectedAnalysisType && dataDelivery && (
                  <>
                    {isStandaloneSampleForm(selectedAnalysisType) ? (
                      <StandaloneSampleFormList
                        form={form}
                        selectedAnalysisType={selectedAnalysisType}
                        options={options}
                        skipReceptionControl={isSkipReceptionControl}
                        applicationTags={applicationTags}
                      />
                    ) : (
                      <CaseFormList
                        form={form}
                        selectedAnalysisType={selectedAnalysisType}
                        options={options}
                        customer={customer}
                        validationError={validationError}
                        skipReceptionControl={isSkipReceptionControl}
                        applicationTags={applicationTags}
                      />
                    )}
                  </>
                )}
              </Form>
            </Spin>
          </Card>
        </>
      )}
    </div>
  )
}
