import React from 'react'
import { Form, Select } from 'antd'

export const ContainerField = ({
  index,
  prefillConditionalFields,
  setContainerNameRequirement,
  getAvailableContainersType,
  analysisType,
  required,
}) => {
  return (
    <Form.Item label="Container" name={[index, 'container']} rules={[{ required: required }]}>
      <Select
        style={{ width: 170 }}
        onChange={(value) => {
          prefillConditionalFields()
          setContainerNameRequirement(value === '96 well plate')
        }}
      >
        {getAvailableContainersType(analysisType).map((container) => (
          <Select.Option key={container} value={container}>
            {container}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
