import React from 'react'
import { GeneralTable } from 'components/GeneralTable/GeneralTable'
import { getCases } from '../../services/StatusDbApi'
import { Link } from 'react-router-dom'
import { Tag } from 'antd'
import {
  getDeliveryTagColor,
  getPipelineTagColor,
  getPriorityTagColor,
  getStatusTagColor,
} from '../../services/helpers/styleHelpers'
import { formatDate, sortDate } from '../../services/helpers/helpers'

export const CasesPage = () => {
  const columns: any = [
    {
      title: 'Name',
      key: 'name',
      render: (cases) => (
        <>
          <Link to={`/cases/${cases.internal_id}`}>{cases.name}</Link>
        </>
      ),
    },
    {
      title: 'Samples',
      key: 'internal_id',
      render: (cases) => <>{cases.links.length}</>,
    },
    {
      title: 'Type',
      dataIndex: 'data_analysis',
      key: 'data_analysis',
      render: (data_analysis) =>
        data_analysis && (
          <Tag color={getPipelineTagColor(data_analysis)}>{data_analysis?.toUpperCase()}</Tag>
        ),
    },
    {
      title: 'Delivery',
      dataIndex: 'data_delivery',
      key: 'data_delivery',
      render: (data_delivery) =>
        data_delivery && (
          <Tag color={getDeliveryTagColor(data_delivery)}>{data_delivery?.toUpperCase()}</Tag>
        ),
    },
    {
      title: 'Customer',
      dataIndex: ['customer', 'internal_id'],
      key: 'customer',
    },
    {
      title: 'Priority',
      dataIndex: ['customer', 'priority'],
      key: 'priority',
      render: (priority) =>
        priority && <Tag color={getPriorityTagColor(priority)}>{priority?.toUpperCase()}</Tag>,
    },
    {
      title: 'Panels',
      dataIndex: 'panels',
      key: 'panels',
    },
    {
      title: 'Status',
      dataIndex: 'action',
      key: 'action',
      filters: [
        {
          text: 'Analyze',
          value: 'analyze',
        },
        {
          text: 'Running',
          value: 'running',
        },
        {
          text: 'Hold',
          value: 'hold',
        },
      ],
      render: (status) => status && <Tag color={getStatusTagColor(status)}>{status}</Tag>,
      filterMultiple: false,
    },
    {
      title: 'Ordered',
      dataIndex: 'ordered_at',
      key: 'ordered_at',
      render: (ordered_at) => formatDate(ordered_at),
      sorter: (a, b) => sortDate(a.ordered_at, b.ordered_at),
    },
  ]
  return <GeneralTable getItems={getCases} columns={columns} title={'Cases'} />
}
