import React from 'react'
import { Avatar } from 'antd'

export const UserAvatar = ({ user }) => (
  <Avatar
    size={'large'}
    style={{
      color: '#202020',
      backgroundColor: '#DCDCDC',
      fontWeight: 'bold',
    }}
  >
    {getInititals(user.name)}
  </Avatar>
)

const getInititals = (fullName: string): string[] =>
  fullName.split(' ').map((fragment) => fragment[0])
