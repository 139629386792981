import React from 'react'
import styles from './Footer.module.css'
import kiLogo from '../../assets/ki-logo.png'
import sciLogo from '../../assets/scilifelab-logo.png'
import kthLogo from '../../assets/kth-logo.png'
import sweLogo from '../../assets/swedac-logo.png'

const Footer: React.FC = () => {
  return (
    <footer data-testid="footer" className={styles.footer}>
      <div className={styles.sponsors}>
        <img className={styles.logo} src={kiLogo} alt={'CG Logo'} />
        <img className={styles.logo} src={kthLogo} alt={'CG Logo'} />
        <img className={styles.logo} src={sciLogo} style={{ height: 50 }} alt={'CG Logo'} />
        <img className={styles.logo} src={sweLogo} alt={'CG Logo'} />
      </div>
      <div className={styles.bottomFooter}>
        <div className={styles.footerElement}>
          <div className={styles.footerTitle}>Contact</div>
          <div>Contact for general questions</div>
          <div>support@clinicalgenomics.se</div>
          +46 85 248 15 00
        </div>
        <div className={styles.footerElement}>
          <div className={styles.footerTitle}>Visiting address</div>
          <div>
            Clinical Genomics
            <br /> Gamma 4 Tomtebodavägen 23A <br /> 171 65 Solna <br /> Sverige
          </div>
        </div>
        <div className={styles.footerElement}>
          <div className={styles.footerTitle}>Mailing address</div>
          <div>
            Clinical Genomics / SciLifeLab
            <br /> Att: Clinical Genomics, Gamma 4 <br /> PO Box 1031 <br /> 171 21 Solna, Sverige
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
