import React from 'react'
import { Form, Input, Tooltip } from 'antd'

export const QuantityNGField = ({ index }) => {
  return (
    <Tooltip title={'Quantity (ng)'}>
      <Form.Item label="ng" name={[index, 'quantity']}>
        <Input type={'number'} style={{ width: 60 }} />
      </Form.Item>
    </Tooltip>
  )
}
