import { pick } from 'ramda'
import { Sample } from '../../services/interfaces'
import { getApplication, postOrder } from '../../services/StatusDbApi'
import {
  ErrorNotification,
  getIndexSequence,
  SuccessNotification,
} from '../../services/helpers/helpers'
import dayjs from 'dayjs'
import { orderTypes } from 'services/helpers/constants'

export const plateLength = 96
const oldSampleTresholdDate = dayjs('2016-12-01')

export const getIsPlate = (container) => container?.includes('96 well plate')
export const isPANPAZApplication = (application) =>
  application?.includes('PAN') || application?.includes('PAZ')
export const isBufferRequired = (application) =>
  application?.includes('PA') || application?.includes('EX')

export const buildCaseFormFromFile = (fileResponse, fileName, form) => {
  const localOrder = {
    name: fileResponse.name || fileName,
    comment: fileResponse.comment,
    customer: fileResponse.customer,
    data_delivery: fileResponse.delivery_type,
    project_type: fileResponse.project_type,
    cases: fileResponse.cases.map((localCase) => {
      return {
        ...localCase,
        samples: localCase.samples.map((sample) => {
          const localSample = pick(
            [
              'name',
              'concentration_ng_ul',
              'data_analysis',
              'data_delivery',
              'application',
              'mother',
              'father',
              'family_name',
              'case_internal_id',
              'require_qc_ok',
              'sex',
              'source',
              'source_comment',
              'priority',
              'formalin_fixation_time',
              'post_formalin_fixation_time',
              'tissue_block_size',
              'cohorts',
              'phenotype_groups',
              'phenotype_terms',
              'subject_id',
              'synopsis',
              'age_at_sampling',
              'comment',
              'control',
              'elution_buffer',
              'container',
              'container_name',
              'well_position',
              'volume',
              'quantity',
              'panels',
              'status',
              'capture_kit',
              'tumour_purity',
              'tumour',
              'reference_genome',
            ],
            sample
          )
          // Needed to filter arrays returned as null from the backend. Ideally the backend should return an empty array
          return {
            ...localSample,
            phenotype_groups: localSample.phenotype_groups || [],
            phenotype_terms: localSample.phenotype_terms || [],
            container_name:
              localSample?.container === '96 well plate'
                ? localSample?.container_name || fileResponse.name
                : localSample?.container_name || localSample?.name,
            volume: +localSample.volume,
          }
        }),
      }
    }),
  }
  form.setFieldsValue(localOrder)
}

export const buildStandaloneSampleFormFromFile = (fileResponse, fileName, form) => {
  const localOrder = {
    name: fileResponse.name || fileName,
    comment: fileResponse.comment,
    customer: fileResponse.customer,
    project_type: fileResponse.project_type,
    data_delivery: fileResponse.delivery_type,
    samples: fileResponse.samples.map((sample) => {
      return {
        application: sample.application,
        capture_kit: sample.capture_kit,
        comment: sample.comment,
        concentration: sample.concentration,
        concentration_ng_ul: sample.concentration_ng_ul,
        concentration_sample: sample.concentration_sample,
        container: sample.container,
        container_name: sample.container_name,
        control: sample.control,
        data_analysis: sample.data_analysis,
        data_delivery: sample.data_delivery,
        elution_buffer: sample.elution_buffer,
        extraction_method: sample.extraction_method,
        index: sample.index,
        index_number: sample.index_number,
        index_sequence: getIndexSequence(sample.index, sample.index_number),
        lab_code: sample.lab_code,
        name: sample.name,
        organism: sample.organism,
        organism_other: sample.organism_other,
        original_lab: sample.original_lab,
        original_lab_address: sample.original_lab_address,
        pool: sample.pool,
        pre_processing_method: sample.pre_processing_method,
        primer: sample.primer,
        priority: sample.priority,
        quantity: sample.quantity,
        reference_genome: sample.reference_genome,
        region: sample.region,
        region_code: sample.region_code,
        rml_plate_name: sample.rml_plate_name,
        well_position_rml: sample.well_position_rml,
        selection_criteria: sample.selection_criteria,
        sex: sample.sex,
        source: sample.source,
        source_comment: sample.source_comment,
        subject_id: sample.subject_id,
        volume: typeof sample.volume === 'string' ? +sample.volume : sample.volume,
        well_position: sample.well_position,
        collection_date: dayjs(sample.collection_date),
        require_qc_ok: !!sample.require_qc_ok,
      }
    }),
  }
  form.setFieldsValue(localOrder)
}

export const validatePanels = (userPanels, allowedPanels) => {
  return userPanels.every((panel) => allowedPanels.includes(panel))
    ? Promise.resolve()
    : Promise.reject()
}

export const validateSubjectID = (subjectID, caseName, sampleId) => {
  if (subjectID === sampleId || subjectID === caseName) return Promise.reject()
  else return Promise.resolve()
}

export const getConcentrationMessage = (skipReceptionControl: boolean, minimum, maximum) => {
  if (!maximum) {
    if (!minimum) {
      return ''
    }
    return '>' + minimum + ' ng/μL'
  } else {
    return minimum + ' to ' + maximum + ' ng/μL'
  }
}

export const getConcentrationRules = (skipReceptionControl, applicationObj, source) => {
  let minimum = applicationObj?.sample_concentration_minimum
  let maximum = applicationObj?.sample_concentration_maximum
  if (source == 'cell-free DNA') {
    minimum = applicationObj?.sample_concentration_minimum_cfdna
    maximum = applicationObj?.sample_concentration_maximum_cfdna
  }
  return applicationObj
    ? [
        {
          min: parseFloat(minimum),
          max: parseFloat(maximum),
          message: getConcentrationMessage(skipReceptionControl, minimum, maximum),
          validator: (rule, value) => {
            return validateConcentration(value, parseFloat(minimum), parseFloat(maximum))
          },
          step: 0.01,
        },
      ]
    : []
}

export const validateConcentration = (value, minimum, maximum) => {
  if (!minimum) {
    return Promise.reject()
  }
  if (!maximum) {
    return value >= minimum ? Promise.resolve() : Promise.reject()
  }
  return value >= minimum && value <= maximum ? Promise.resolve() : Promise.reject()
}

export const validateBaitSet = (baitSet, isRequired, options) => {
  return !isRequired || options.beds.includes(baitSet) ? Promise.resolve() : Promise.reject()
}

export const buildCaseOrder = (formInput) => {
  const sampleList: Sample[] = []
  const dataAnalysis = orderTypes.find(
    (item) => item.orderType === formInput.project_type
  )?.dataAnalysis
  const localOrder = {}
  localOrder['customer'] = formInput.customer
  localOrder['comment'] = formInput.comment
  localOrder['skip_reception_control'] = formInput.skip_reception_control
  localOrder['name'] = formInput.name
  formInput.cases.forEach((localCase) => {
    localCase?.samples.map((sample) =>
      sampleList.push({
        ...sample,
        data_analysis: dataAnalysis,
        data_delivery: formInput.data_delivery,
        family_name: localCase.name,
        panels: localCase.panels,
        cohorts: localCase.cohorts || [],
        phenotype_groups: sample.phenotype_groups || [],
        phenotype_terms: sample.phenotype_terms || [],
        case_internal_id: localCase.internal_id,
        synopsis: localCase.synopsis,
        priority: localCase.priority,
        source: sample.source === 'other' ? sample.source_comment : sample.source,
        source_comment: sample.source_comment,
      })
    )
  })
  localOrder['samples'] = [...sampleList]
  return localOrder
}

export const buildStandaloneOrder = (formInput) => {
  const sampleList: Sample[] = []
  const dataAnalysis = orderTypes.find(
    (item) => item.orderType === formInput.project_type
  )?.dataAnalysis
  const localOrder = {}
  localOrder['customer'] = formInput.customer
  localOrder['comment'] = formInput.comment
  localOrder['skip_reception_control'] = formInput.skip_reception_control
  localOrder['name'] = formInput.name
  formInput?.samples.map((sample) =>
    sampleList.push({
      ...sample,
      data_analysis: dataAnalysis,
      data_delivery: formInput.data_delivery,
      collection_date: dayjs(sample.collection_date).format('YYYY-MM-DD'),
      index_sequence: getIndexSequence(sample.index, sample.index_number),
    })
  )
  localOrder['samples'] = [...sampleList]
  return localOrder
}

export const submitOrder = (
  userContext,
  orderType,
  order,
  form,
  setIsConfirmationModalOpen,
  setIsSubmittingOrder
) => {
  setIsSubmittingOrder(true)
  postOrder(userContext, orderType, order)
    .then(() => {
      SuccessNotification('Order submitted!')
      form.resetFields()
      setIsConfirmationModalOpen(false)
      setIsSubmittingOrder(false)
    })
    .catch(({ response }) => {
      setIsSubmittingOrder(false)
      ErrorNotification('Could not submit the order', response.data.message)
    })
}

export const validateCaseOrder = async (
  formInput,
  userContext,
  setValidationError,
  setIsConfirmationModalOpen,
  setOrderData
) => {
  const errorMessages: string[] = []
  const hasCase = !!formInput.cases?.length
  if (hasCase) {
    setOrderData(buildCaseOrder(formInput))
    if (!formInput.skip_reception_control) {
      setIsConfirmationModalOpen(true)
    }
    if (formInput.skip_reception_control) {
      const passedSkipQC = await validateSkipQC(
        formInput,
        errorMessages,
        setIsConfirmationModalOpen,
        userContext
      )
      if (passedSkipQC) {
        setIsConfirmationModalOpen(true)
      }
    }
  } else {
    errorMessages.push('Validation error: Add at least one case to the order')
  }
  setValidationError({ hasError: errorMessages.length > 0, messages: errorMessages })
}
export const validateSkipQC = async (
  formInput,
  errorMessages,
  setIsConfirmationModalOpen,
  userContext
) => {
  let hasError = false
  for (const { samples } of formInput.cases) {
    for (const { name, concentration_ng_ul, elution_buffer, application, source } of samples) {
      const okayConcentration = await validateConcentrationLevel(
        concentration_ng_ul,
        application,
        source,
        userContext
      )
      if (!okayConcentration) {
        setIsConfirmationModalOpen(false)
        errorMessages.push(
          'Validation error: Please ensure that extra requirements for skipping quality control are met.' +
            name
        )
        hasError = true
      }
      if (elution_buffer?.includes('Other')) {
        setIsConfirmationModalOpen(false)
        errorMessages.push(
          'Validation error: Please ensure that extra requirements for skipping quality control are met.'
        )
        hasError = true
      }
    }
  }
  return !hasError
}

export const validateConcentrationLevel = async (
  concentration,
  application,
  source,
  userContext
) => {
  if (!concentration) {
    return false
  }
  const applicationObj = await getApplication(userContext, application)
  let concentration_minimum = applicationObj.sample_concentration_minimum
  let concentration_maximum = applicationObj.sample_concentration_maximum
  if (source == 'cell-free DNA') {
    concentration_minimum = applicationObj.sample_concentration_minimum_cfdna
    concentration_maximum = applicationObj.sample_concentration_maximum_cfdna
  }
  if (!concentration_minimum) {
    return false
  }
  if (concentration_maximum) {
    return (
      concentration >= parseFloat(concentration_minimum) &&
      concentration <= parseFloat(concentration_maximum)
    )
  }
  return concentration >= parseFloat(concentration_minimum)
}

export const validateStandaloneOrder = (
  formInput,
  userContext,
  setValidationError,
  setIsConfirmationModalOpen,
  setOrderData
) => {
  const errorMessages: string[] = []
  const hasSample = !!formInput.samples?.length
  if (hasSample) {
    setOrderData(buildStandaloneOrder(formInput))
    setIsConfirmationModalOpen(true)
  } else {
    errorMessages.push('Validation error: Add at least one case to the order')
  }
  setValidationError({ hasError: errorMessages.length > 0, messages: errorMessages })
}

export const buildExistingSample = (backendSample) => {
  const localSample = pick(
    [
      'application',
      'name',
      'mother',
      'father',
      'status',
      'internal_id',
      'sex',
      'received_at',
      'subject_id',
      'reference_genome',
    ],
    backendSample
  )
  localSample['application'] = backendSample?.application?.tag
  localSample['tumour'] = backendSample?.is_tumour
  localSample['concentration'] = backendSample?.concentration
  return localSample
}

export const buildExistingFamily = (backendFamily) => {
  const localFamily = pick(
    ['name', 'cohorts', 'panels', 'internal_id', 'sex', 'priority'],
    backendFamily
  )
  localFamily.samples = backendFamily.links.map((link) => {
    const referenceGenome = link.sample?.reference_genome
    return buildExistingSample({
      ...link.sample,
      father: link.father?.name,
      mother: link.mother?.name,
      status: link.status,
      reference_genome: referenceGenome,
    })
  })
  return localFamily
}

export const handleTumorCaseCount = (samples) => {
  let tumorCount = 0
  let nonTumorCount = 0

  samples.map((sample) => {
    if (sample?.tumour) {
      tumorCount++
    } else {
      nonTumorCount++
    }
  })
  return { tumorCount, nonTumorCount }
}

export const getBackgroundColor = (index) => (index % 2 === 0 ? '#f3f3f3' : '#fbfbfb')

export const findUniqueItems = (items) => [...new Map(items.map((v) => [v.name[1], v])).values()]

export const removeFileExtension = (fileName) => fileName.split('.').slice(0, -1).join('.')

export const isSampleOld = (sampleReceivedDate) =>
  dayjs(sampleReceivedDate).isBefore(oldSampleTresholdDate)

const sampleInWell = (well, containerName, sample) =>
  sample.container_name === containerName && sample.well_position === well

const wellSampleCount = (well, containerName, samples) => {
  let count = 0
  samples.forEach((sample) => {
    if (sampleInWell(well, containerName, sample)) {
      count++
    }
  })
  return count
}

export const validateWellIsFree = (well, containerName, samples) => {
  const count = wellSampleCount(well, containerName, samples)
  return count > 1 ? Promise.reject() : Promise.resolve()
}

export const initIsTrustedCustomer = (customer, options) => {
  const option = options?.customers?.find((option) => option.value === customer)
  return option ? option.isTrusted : false
}
