import { ClinicalCookies } from '../interfaces'
import { notification } from 'antd'
import Cookies from 'universal-cookie'
import moment from 'moment'
import { indexSequences } from './indexSequences'

export const ErrorNotification = (message, description) => {
  const key = `open${Date.now()}`
  notification.error({
    message,
    description,
    key,
    duration: 0,
  })
}

export const SuccessNotification = (message) => {
  const key = `open${Date.now()}`
  notification.success({
    message,
    key,
    duration: 0,
  })
}

export const setCookie = (value, cookieName) => {
  const cookies = new Cookies()
  cookies.set(cookieName, JSON.stringify(value), { path: '/', secure: true })
}

export const getCookies = (): ClinicalCookies => {
  const cookies = new Cookies()
  const allCookies: ClinicalCookies = cookies.getAll()
  return allCookies
}

export const resetCookies = () => {
  const cookies = new Cookies()
  Object.keys(cookies.getAll()).forEach((cookie) => {
    cookies.remove(cookie)
  })
}

export const formatDate = (date: string) => (date ? moment(date).format('YYYY-MM-DD') : null)

export const formatDateTime = (dateTimeString: string) =>
  dateTimeString ? moment(dateTimeString).format('YYYY-MM-DD HH:mm:ss') : null

export const escapeRegExp = (input) => {
  return input.replace(/[^A-Za-z0-9 _]/g, '\\$&')
}

export const sortDate = (dateA: string, dateB: string) => {
  return new Date(dateA).getTime() - new Date(dateB).getTime()
}

export const getIndexSequence = (indexName: string, indexNumber: number): string => {
  try {
    return indexSequences[indexName]?.[indexNumber - 1]
  } catch {
    return 'No Index'
  }
}
