import * as React from 'react'
import styles from './HomePage.module.css'
import banner from '../../assets/hero.png'
import { News } from '../../components/News/News'

export const HomePage = ({ posts }) => (
  <div className={styles.homeContainer}>
    <img className={styles.mainLogo} src={banner} alt={'CG Logo'} />
    <div className={styles.news}>
      <News posts={posts} />
    </div>
  </div>
)
