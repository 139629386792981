import React from 'react'
import { Form, InputNumber, Tooltip } from 'antd'

export const VolumeField = ({ index }) => {
  return (
    <Tooltip title={'Volume (μL). Must be between 20-130 μL'}>
      <Form.Item
        label="Volume (μL)"
        name={[index, 'volume']}
        rules={[
          { required: true },
          {
            min: 20,
            max: 130,
            message: '20 to 130 μL',
            type: 'number',
          },
        ]}
        required
      >
        <InputNumber style={{ width: 90 }} />
      </Form.Item>
    </Tooltip>
  )
}
