import React from 'react'
import { Form, Select } from 'antd'
import { getApplication } from '../../../services/StatusDbApi'

export const ApplicationField = ({
  index,
  isExisting,
  setApplication,
  setApplicationObj,
  skipReceptionControl,
  userContext,
  applicationTags,
}) => {
  return (
    <Form.Item
      label="Application tag"
      name={[index, 'application']}
      rules={[{ required: true }]}
      required
    >
      <Select
        style={{ width: 170 }}
        disabled={isExisting}
        onChange={async (e) => {
          setApplication(e)
          if (e && skipReceptionControl) {
            const newApplicationObj = await getApplication(userContext, e)
            setApplicationObj(newApplicationObj)
          } else {
            setApplicationObj(null)
          }
        }}
      >
        {applicationTags?.map((tag) => (
          <Select.Option key={tag} value={tag}>
            {tag}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  )
}
