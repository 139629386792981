import React from 'react'
import { App } from './App'
import * as serviceWorker from './serviceWorker'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { createRoot } from 'react-dom/client'

const container = document.getElementById('root')
const root = createRoot(container)

// Check if the mock API is enabled (via REACT_APP_MOCK_API environment variable)
// If enabled, require the mock service worker module to intercept network requests
if (process.env.REACT_APP_MOCK_API === 'true') {
  require('./mocks/browser')
}

root.render(
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#2992DB',
      },
      components: { Menu: { itemSelectedColor: '#2992DB' } },
    }}
  >
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </ConfigProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
