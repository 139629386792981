import React from 'react'
import { Form, Select, Tooltip } from 'antd'

export const ExtractionMethodField = ({ index, extractionMethods }) => {
  return (
    <Tooltip title={'DNA Extraction method'}>
      <Form.Item
        label="Extraction method"
        name={[index, 'extraction_method']}
        required
        rules={[{ required: true }]}
      >
        <Select style={{ width: 240 }}>
          {extractionMethods.map((method) => (
            <Select.Option key={method}>{method}</Select.Option>
          ))}
        </Select>
      </Form.Item>
    </Tooltip>
  )
}
