import React from 'react'
import { Button, Form } from 'antd'

export const SaveButton = ({ validateSample }) => {
  return (
    <Form.Item>
      <Button type="primary" onClick={() => validateSample()}>
        Save
      </Button>
    </Form.Item>
  )
}
