import React from 'react'
import { Form, Switch, Tooltip } from 'antd'

export const ReqQCField = ({ index, isExisting }) => {
  return (
    <Tooltip title="Require quality control to continue">
      <Form.Item label="Req. QC" name={[index, 'require_qc_ok']} valuePropName="checked">
        <Switch disabled={isExisting} />
      </Form.Item>
    </Tooltip>
  )
}
