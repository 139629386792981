import React from 'react'
import { Form, Input } from 'antd'

export const SampleNameField = ({
  index,
  isExisting,
  setSampleId,
  isMIPDNA,
  isTomte,
  analysisType,
  buildParentsObject,
}) => {
  return (
    <Form.Item
      label="Name"
      name={[index, 'name']}
      rules={[
        {
          required: true,
          message: 'required',
        },
        {
          message: 'Can include only numbers, letters and dash',
          pattern: new RegExp(/^[a-zA-Z0-9-]*$/),
        },
      ]}
      required
    >
      <Input
        style={{ width: 200 }}
        disabled={isExisting}
        onChange={(e) => {
          setSampleId(e.target.value)
          if (isMIPDNA(analysisType) || isTomte(analysisType)) buildParentsObject(e.target.value)
        }}
      />
    </Form.Item>
  )
}
